import classnames from 'clsx';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changePlaybackRateIfPossible } from '../../../actions/tuner';
import withOutsideClickControl from '../../../decorators/withOutsideClickControl';
import Popover from '../../shared/Popover';
import PlayBackRateOption from './PlayBackRateOption';
import css from './speed-control.module.scss';

const playbackRateOptions = [0.5, 0.8, 1.0, 1.2, 1.5, 2.0, 3.0];
const mobilePlaybackRateOptions = [0.5, 1.0, 1.5, 2.0];

export function SpeedControl({
  iconContainerClass,
  playbackRate,
  handleOnClick,
  isOpen,
  containerRef,
  isMobile,
  actions,
}) {
  if (isMobile) {
    const handleMobileOnClick = () => {
      const currentIndex = mobilePlaybackRateOptions.indexOf(playbackRate);
      actions.changePlaybackRateIfPossible(
        mobilePlaybackRateOptions[currentIndex + 1] ||
          mobilePlaybackRateOptions[0],
      );
    };

    return (
      <div className={iconContainerClass} onClick={handleMobileOnClick}>
        <div
          className={classnames(
            css.speedControlText,
            css.mobileSpeedControlText,
          )}
        >
          {`${playbackRate}x`}
        </div>
      </div>
    );
  }

  return (
    <div ref={containerRef}>
      <button
        type="button"
        className={iconContainerClass}
        onClick={handleOnClick}
      >
        <div className={css.speedControlText}>{`${playbackRate}x`}</div>
      </button>
      <Popover isOpen={isOpen} className={css.popover}>
        <div className={css.content}>
          {playbackRateOptions.map((val) => (
            <PlayBackRateOption
              key={val}
              value={val}
              className={css.row}
              dataTestId={`rate-${val}`}
            />
          ))}
        </div>
      </Popover>
    </div>
  );
}

const mapStateToProps = (state) => ({
  playbackRate: state.player?.playbackRate || 1.0,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        changePlaybackRateIfPossible,
      },
      dispatch,
    ),
  };
}

SpeedControl.propTypes = {
  iconContainerClass: PropTypes.string,
  playbackRate: PropTypes.number,
  handleOnClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  containerRef: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
};

export default flow(
  connect(mapStateToProps, mapDispatchToProps),
  withOutsideClickControl,
)(SpeedControl);
