import PropTypes from 'prop-types';
import cssVariables from 'src/common/styles/variables';

/* eslint-disable max-len */
const VolumeControlOffIcon = ({
  width = '30px',
  height = '30px',
  viewBox = '0 0 30 30',
  onClick,
  className,
  fillColor,
}) => (
  <svg
    width={width}
    height={height}
    data-testid="muted"
    onClick={onClick}
    viewBox={viewBox}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.61977 10.4286H3V19.5714H7.61977V10.4286Z"
      stroke={fillColor || cssVariables['--secondary-color-5']}
      strokeWidth="1.5"
    />
    <path
      d="M14.5498 23H12.2399L7.62012 19.5714V10.4286L12.2399 7H14.5498V23Z"
      stroke={fillColor || cssVariables['--secondary-color-5']}
      strokeWidth="1.5"
    />
    <path
      d="M20.9102 11.7402C21.8802 12.5702 22.5002 13.7802 22.5002 15.1502C22.5002 16.5202 21.8702 17.7302 20.9102 18.5602"
      stroke={fillColor || cssVariables['--secondary-color-5']}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M24.1006 21.7498C25.8806 20.1098 27.0006 17.7598 27.0006 15.1498C27.0006 12.5398 25.8806 10.1898 24.1006 8.5498"
      stroke={fillColor || cssVariables['--secondary-color-5']}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M20.9102 18.5602C21.8802 17.7302 22.5002 16.5202 22.5002 15.1502C22.5002 13.7802 21.8702 12.5702 20.9102 11.7402"
      stroke={fillColor || cssVariables['--secondary-color-5']}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

VolumeControlOffIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
  fillColor: PropTypes.string,
};

export default VolumeControlOffIcon;
