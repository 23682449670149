import classnames from 'clsx';
import flow from 'lodash/flow';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changePlaybackRateIfPossible } from '../../../actions/tuner';
import cssVariables from '../../../styles/variables';
import Checkmark from '../../shared/svgIcons/Checkmark';
import css from './playback-rate-option.module.scss';

export function PlayBackRateOption({
  value,
  selectedRate,
  className,
  actions,
}) {
  const formattedPlaybackRate = `${value.toString().padEnd(3, '.0')}x`;

  return (
    <div
      className={classnames(css.value, className)}
      onClick={() => actions.changePlaybackRateIfPossible(value, true)}
    >
      {formattedPlaybackRate}
      {value === selectedRate && (
        <Checkmark
          width="40px"
          height="40px"
          viewBox="0 0 30 30"
          fill={cssVariables['--secondary-color-5']}
        />
      )}
    </div>
  );
}

PlayBackRateOption.propTypes = {
  value: PropTypes.number,
  selectedRate: PropTypes.number,
  className: PropTypes.string,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  selectedRate: get(state, 'player.playbackRate', 1.0),
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        changePlaybackRateIfPossible,
      },
      dispatch,
    ),
  };
}

export default flow(connect(mapStateToProps, mapDispatchToProps))(
  PlayBackRateOption,
);
