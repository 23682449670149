import classNames from 'clsx';
import PropTypes from 'prop-types';
import Slider from './Slider';
import css from './scrubber.module.scss';

export default function DefaultScrubber({
  canScrub,
  sliderMax,
  elapsedTime,
  durationTime,
  showStartEnd,
  elapsedPercent,
  elapsedSeconds,
  onChangeCommitted,
  isInNowPlayingDialog,
  className,
}) {
  const showDuration = showStartEnd && canScrub;
  const elapsedCss = isInNowPlayingDialog ? css.nowPlayingElapsed : css.elapsed;
  const durationCss = isInNowPlayingDialog
    ? css.nowPlayingDuration
    : css.duration;
  const elapsed = canScrub ? elapsedPercent : 0;
  const sliderPreText = isInNowPlayingDialog ? 'LIVE' : '';

  return (
    <div
      data-testid="scrubberWrapper"
      className={classNames(className, {
        [css.scrubberContainer]: isInNowPlayingDialog,
      })}
    >
      {showStartEnd && canScrub && (
        <div data-testid="elapsedWrapper" className={elapsedCss}>
          <span data-elapsedtime={elapsedSeconds} id="scrubberElapsed">
            {canScrub ? elapsedTime : sliderPreText}
          </span>
        </div>
      )}
      <Slider
        canScrub={canScrub}
        isDisabled={!canScrub}
        elapsedPercent={elapsed}
        sliderMax={sliderMax}
        onChangeCommitted={onChangeCommitted}
        isInNowPlayingDialog={isInNowPlayingDialog}
      />
      {showDuration && (
        <div id="scrubberDuration" className={durationCss}>
          {durationTime}
        </div>
      )}
    </div>
  );
}

DefaultScrubber.propTypes = {
  canScrub: PropTypes.bool,
  showStartEnd: PropTypes.bool.isRequired,
  elapsedPercent: PropTypes.number.isRequired,
  elapsedSeconds: PropTypes.number.isRequired,
  sliderMax: PropTypes.number.isRequired,
  durationTime: PropTypes.string,
  elapsedTime: PropTypes.string.isRequired,
  onChangeCommitted: PropTypes.func.isRequired,
  isInNowPlayingDialog: PropTypes.bool,
  className: PropTypes.string,
};
