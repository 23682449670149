import classNames from 'clsx';
import MaterialSlider from 'material-ui/Slider';
import PropTypes from 'prop-types';
import { Component } from 'react';
import css from './scrubber.module.scss';

const SLIDER_MAX = 100;

export default class Slider extends Component {
  static propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    className: PropTypes.string,
    canScrub: PropTypes.bool.isRequired,
    sliderMax: PropTypes.number.isRequired,
    elapsedPercent: PropTypes.number.isRequired,
    onChangeCommitted: PropTypes.func.isRequired,
    isInNowPlayingDialog: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      scrubbingPercent: 0,
    };

    this.onChange = this.onChange.bind(this);
    this.onDragStop = this.onDragStop.bind(this);
  }

  onChange(event, scrubbingPercent) {
    this.setState({ scrubbingPercent });
  }

  onDragStop() {
    this.props.onChangeCommitted(this.state.scrubbingPercent);
  }

  render() {
    const {
      canScrub,
      isDisabled,
      sliderMax,
      elapsedPercent,
      isInNowPlayingDialog,
      className,
    } = this.props;

    const sliderCss = isInNowPlayingDialog ? css.nowPlayingSlider : css.slider;

    return (
      <MaterialSlider
        id="scrubber"
        data-testid={`scrubber-disabled-${isDisabled}`}
        disableFocusRipple
        className={classNames(sliderCss, className, {
          [css.isLive]: !canScrub,
          [css.disabled]: isDisabled,
        })}
        disabled={isDisabled}
        value={Math.min(elapsedPercent, SLIDER_MAX)}
        max={sliderMax}
        onChange={this.onChange}
        onDragStop={this.onDragStop}
        sliderStyle={{ margin: 0 }}
      />
    );
  }
}
