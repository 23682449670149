import isUndefined from 'lodash/isUndefined';

function padWithZero(number) {
  return number < 10 ? `0${number}` : number;
}

function getTimeParts(totalSeconds) {
  return {
    hours: Math.floor(totalSeconds / 3600),
    minutes: padWithZero(Math.floor((totalSeconds % 3600) / 60)),
    seconds: padWithZero(Math.floor(totalSeconds % 60)),
  };
}

export default function secondsToTime(totalSeconds) {
  if (isUndefined(totalSeconds)) {
    return null;
  }

  const time = getTimeParts(totalSeconds);
  const timeParts = time.hours ? [time.hours] : [];
  timeParts.push(time.minutes, time.seconds);

  return timeParts.join(':');
}

export function secondsToTimeWithHour(totalSeconds) {
  if (isUndefined(totalSeconds)) {
    return null;
  }

  const time = getTimeParts(totalSeconds);
  return [padWithZero(time.hours), time.minutes, time.seconds].join(':');
}
