import classNames from 'clsx';
import Slider from 'material-ui/Slider';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { setVolume, toggleMute } from 'src/common/actions/player';
import Popover from 'src/common/components/shared/Popover';
import VolumeControlIcon from 'src/common/components/shared/svgIcons/player/VolumeControlIcon';
import VolumeControlOffIcon from 'src/common/components/shared/svgIcons/player/VolumeControlOffIcon';
import withOutsideClickControl from 'src/common/decorators/withOutsideClickControl';
import useActions from 'src/common/hooks/useActions';
import { useSetPlayerVolume } from 'src/common/hooks/useSetPlayerVolume';
import { selectIsMobile } from 'src/common/selectors/app';
import {
  selectPlayerMuted,
  selectPlayerVolume,
  selectShowVolumeBar,
} from 'src/common/selectors/player';
import cssVars from 'src/common/styles/variables';
import { isInDiscordIFrame } from '../../../../utils/discord';
import css from './volume-control.module.scss';

const defaultSliderStyles = {
  slider: {
    height: 120,
    marginTop: '10px',
    marginBottom: '10px',
    backgroundColor: cssVars['--content-area-background-color-hex'],
    padding: 10,
    borderRadius: '26px',
  },
  inner: {
    marginTop: 0,
    cursor: 'pointer',
  },
};

const defaultIconProps = {
  width: cssVars['--large-icon-size'],
  height: cssVars['--large-icon-size'],
};

function VolumeControl({
  volumeSliderClass,
  popoverContainerClass,
  volumeContainerClass,
  iconContainerClass,
  iconProps,
  styles = {},
  isDisabled,

  // via withOutsideClickControl
  containerRef,
  handleOnClick,
  isOpen,
}) {
  const actions = useActions({
    setVolume,
    toggleMute,
  });
  const volume = useSelector(selectPlayerVolume);
  const showVolumeBar = useSelector(selectShowVolumeBar);
  const muted = useSelector(selectPlayerMuted);

  useSetPlayerVolume();

  function handleSliderChange(event, value) {
    actions.setVolume(value);
  }

  const sliderStyles = {
    ...(styles.slider || defaultSliderStyles.slider),
  };
  const innerSliderStyles = styles.inner || defaultSliderStyles.inner;
  const VolumeIconComponent =
    volume && !muted && !isDisabled ? VolumeControlOffIcon : VolumeControlIcon;

  const isMobile = useSelector(selectIsMobile);

  const playerVolumeClick = () => {
    if (!isDisabled && isMobile) {
      actions.toggleMute();
      return;
    }
    if (!isDisabled) {
      handleOnClick();
    }
  };

  return (
    <div
      id="playerVolumeContainer"
      className={volumeContainerClass}
      ref={containerRef}
    >
      <div
        id="playerVolume"
        data-testid="playerVolume"
        data-volume={volume}
        className={classNames(iconContainerClass, {
          [css.disabledVolume]: isDisabled,
        })}
        /* biome-ignore lint/a11y/useSemanticElements: TODO: explore changing div to button */
        role="button"
        tabIndex={0}
        onClick={playerVolumeClick}
        onKeyDown={playerVolumeClick}
      >
        <VolumeIconComponent
          className={css.overflowVisible}
          {...defaultIconProps}
          {...iconProps}
        />
      </div>
      <Popover
        id="playerVolumePopover"
        isOpen={isOpen || showVolumeBar}
        className={classNames(css.popover, popoverContainerClass)}
      >
        <div className={css.content}>
          <Slider
            id="playerVolumeSlider"
            style={sliderStyles}
            sliderStyle={innerSliderStyles}
            className={classNames(css.volumeSlider, volumeSliderClass)}
            value={volume}
            onChange={handleSliderChange}
            max={isInDiscordIFrame() ? 25 : 100}
            axis="y"
          />
        </div>
      </Popover>
    </div>
  );
}

VolumeControl.propTypes = {
  volumeSliderClass: PropTypes.string,
  popoverContainerClass: PropTypes.string,
  volumeContainerClass: PropTypes.string,
  iconContainerClass: PropTypes.string,
  iconProps: PropTypes.object,
  styles: PropTypes.object,
  isDisabled: PropTypes.bool,

  // via withOutsideClickControl
  containerRef: PropTypes.object.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default withOutsideClickControl(VolumeControl, true);
