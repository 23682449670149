import { isTopic } from '@tunein/web-common';
import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import MessagePopoverWithTail from 'src/common/components/messagePopoverWithTail/MessagePopoverWithTail';
import { DISCORD_PLAYBACK_DISABLED_TEXT } from 'src/common/components/player/actionDrawer/DiscordControls/constants';
import CircularProgress from 'src/common/components/shared/CircularProgress';
import PopOutButton from 'src/common/components/shared/button/PopOutButton';
import { playerStatuses } from 'src/common/constants/playerStatuses';
import connectWithPlayer, {
  syncedPlayerActions,
} from 'src/common/decorators/connectWithPlayer';
import useMobilePopout from 'src/common/hooks/useMobilePopout';
import {
  selectDiscordState,
  selectIsDiscord,
  selectIsMobile,
} from 'src/common/selectors/app';
import {
  selectAutoPlayGuideItem,
  selectCanScrub,
  selectExternalHtmlPlayerUrl,
  selectNowPlaying,
  selectNowPlayingRejectReasonKey,
  selectPlayerStatus,
  selectTunedGuideId,
} from 'src/common/selectors/player';
import { selectAllProfiles } from 'src/common/selectors/profiles';
import cssVariables from 'src/common/styles/variables';
import { isMedium } from 'src/common/utils/breakpoints';
import { playerStatusToSvgIconMap } from 'src/common/utils/playerStatusToIconMap';
import css from './player-play-button.module.scss';

export const PlayButton = ({
  breakpoint,
  enableDiscordControlTooltip,
  isLive,
  disablePreroll,
  customFill,
  onClick,
  progressColor,
  customHeight,
  customWidth,
  guideItem: guideItemFromProps,
  className = '',
  playerActions,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const isDiscord = useSelector(selectIsDiscord);
  const discordState = useSelector(selectDiscordState);
  const isMobile = useSelector(selectIsMobile);
  const canScrub = useSelector(selectCanScrub);
  const profiles = useSelector(selectAllProfiles);
  const playerGuideId = useSelector(selectTunedGuideId);
  const playerStatusFromStore = useSelector(selectPlayerStatus);
  const externalHtmlPlayerUrl = useSelector(selectExternalHtmlPlayerUrl);
  const autoPlayGuideItem = useSelector(selectAutoPlayGuideItem);
  const nowPlaying = useSelector(selectNowPlaying);
  const nowPlayingRejectReasonKey = useSelector(
    selectNowPlayingRejectReasonKey,
  );
  const { canShowPopover, handleTargetClick } = useMobilePopout(isMobile, 3000);
  const guideItem =
    (guideItemFromProps?.guideId && guideItemFromProps.guideItem) ||
    profiles[nowPlaying?.primaryGuideId] ||
    (autoPlayGuideItem?.guideId && autoPlayGuideItem) ||
    {};
  const guideIdToPlay = isTopic(nowPlaying?.secondaryGuideId)
    ? nowPlaying?.secondaryGuideId
    : nowPlaying.primaryGuideId || guideItem.guideId;

  const playerStatus =
    guideIdToPlay === playerGuideId ||
    playerStatusFromStore === playerStatuses.connecting
      ? playerStatusFromStore
      : playerStatuses.idle;

  const isMediumBreakpoint = isMedium(breakpoint);
  const predeterminedHeight = isMediumBreakpoint ? 45 : 40;
  const predeterminedWidth = isMediumBreakpoint ? 45 : 40;
  const height = customHeight || predeterminedHeight;
  const width = customWidth || predeterminedWidth;
  const isPopoverOpen = Boolean(
    isDiscord &&
      (!discordState?.canControlPlayback ||
        (nowPlayingRejectReasonKey && guideIdToPlay === playerGuideId)) &&
      ((isMobile && canShowPopover) || (!isMobile && isHovering)),
  );

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handlePlayButtonClick = () => {
    if (onClick) {
      onClick();
    }

    const playerAction = syncedPlayerActions[playerStatus];

    if (playerAction) {
      playerAction(playerActions, canScrub, guideItem, disablePreroll);
    }
  };

  if (playerStatus === playerStatuses.connecting) {
    return (
      <CircularProgress
        id="playerLoadingSpinner"
        className={className}
        data-testid={`player-status-${playerStatus}`}
        size={width}
        color={progressColor || cssVariables['--secondary-color-1']}
        thickness={2}
      />
    );
  }

  if (playerStatus === playerStatuses.popout) {
    return (
      <div className={className} data-testid="popoutButtonWrapper">
        <PopOutButton
          externalHtmlPlayerUrl={externalHtmlPlayerUrl}
          dataTestId={`player-status-${playerStatus}`}
          width={`${width}`}
          height={`${height}`}
        />
      </div>
    );
  }

  const PlayButtonStateRepresentation =
    playerStatusToSvgIconMap[playerStatus](canScrub);

  return (
    <div
      className={classNames(css.playButtonWrapper, {
        [css.isDisabled]:
          isDiscord &&
          guideIdToPlay === playerGuideId &&
          nowPlayingRejectReasonKey,
      })}
      onClick={handleTargetClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {enableDiscordControlTooltip && (
        <MessagePopoverWithTail
          message={
            guideIdToPlay === playerGuideId
              ? nowPlayingRejectReasonKey || DISCORD_PLAYBACK_DISABLED_TEXT
              : DISCORD_PLAYBACK_DISABLED_TEXT
          }
          popoverClassName={classNames(css.popover, {
            [css.isLive]: isLive,
          })}
          isOpen={isPopoverOpen}
        />
      )}
      <PlayButtonStateRepresentation
        id="playerActionButton"
        dataTestId={`player-status-${playerStatus}`}
        className={classNames(css.playerPlayButton, className)}
        onClick={handlePlayButtonClick}
        fillButtonBackground={customFill}
        height={`${height}`}
        width={`${width}`}
      />
    </div>
  );
};

PlayButton.propTypes = {
  breakpoint: PropTypes.number.isRequired,
  enableDiscordControlTooltip: PropTypes.bool.isRequired,
  isLive: PropTypes.bool,
  disablePreroll: PropTypes.bool,
  customFill: PropTypes.string,
  progressColor: PropTypes.string,
  onClick: PropTypes.func,
  guideItem: PropTypes.object,
  className: PropTypes.string,
  customHeight: PropTypes.number,
  customWidth: PropTypes.number,
};

export default connectWithPlayer(PlayButton);
