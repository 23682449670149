import { useSelector } from 'react-redux';
import { setVolume } from '../actions/player';
import {
  selectIsDiscord,
  selectIsFord,
  selectIsMobile,
} from '../selectors/app';
import { selectPlayerVolume } from '../selectors/player';
import { getVolume as getVolumeFromStorage } from '../utils/storage/playerVolume';
import useActions from './useActions';
import useOnMount from './useOnMount';

const DEFAULT_VOLUME_FOR_DISCORD = 20;
const DEFAULT_MOBILE_VOLUME_FOR_DISCORD = 50;

export function useSetPlayerVolume() {
  const actions = useActions({
    setVolume,
  });
  const volume = useSelector(selectPlayerVolume);
  const isDiscord = useSelector(selectIsDiscord);
  const isMobile = useSelector(selectIsMobile);
  const isFord = useSelector(selectIsFord);

  useOnMount(() => {
    const volumeFromStorage = getVolumeFromStorage();
    let volumeToSet;

    if (Number.isFinite(volumeFromStorage)) {
      volumeToSet = volumeFromStorage;
    } else if (isDiscord && isMobile && !isFord) {
      volumeToSet = DEFAULT_MOBILE_VOLUME_FOR_DISCORD;
    } else if (isDiscord && !isFord) {
      volumeToSet = DEFAULT_VOLUME_FOR_DISCORD;
    } else {
      volumeToSet = volume;
    }

    actions.setVolume(volumeToSet);
  });
}
