import PropTypes from 'prop-types';
import { getCssStyle } from '../../../utils/getCssStyle';
/* eslint-disable max-len */

const RewindFifteenIcon = ({
  dataTestId = 'rewindFifteenIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill = getCssStyle('--text-color'),
  className,
  onClick,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    className={className}
    onClick={onClick}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1404 9.33L13.3604 6.67L16.1404 4"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M4.75 16.67C4.75 22.19 9.23 26.67 14.75 26.67C20.27 26.67 24.75 22.19 24.75 16.67C24.75 11.15 20.27 6.66998 14.75 6.66998"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M11.7995 19.88V14.57L10.2695 15.53V14.34L11.8895 13.34H12.9695V19.89H11.7995V19.88Z"
      fill={fill}
    />
    <path
      d="M14.1699 18.18L15.2999 17.83C15.4899 18.57 15.9399 18.95 16.7399 18.95C17.5399 18.95 18.0599 18.44 18.0599 17.7C18.0599 16.96 17.5199 16.44 16.7399 16.44C16.1599 16.44 15.7399 16.66 15.3799 17.02L14.3099 16.74L14.9199 13.33H18.5899V14.4H15.8099L15.5499 15.85H15.5699C15.9499 15.61 16.3799 15.47 16.9199 15.47C18.2799 15.47 19.2299 16.43 19.2299 17.7C19.2299 19.08 18.2099 20.02 16.7399 20.02C15.4399 20.02 14.4599 19.37 14.1699 18.17V18.18Z"
      fill={fill}
    />
  </svg>
);
RewindFifteenIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default RewindFifteenIcon;
