import PropTypes from 'prop-types';
import cssVariables from 'src/common/styles/variables';

/* eslint-disable max-len */
const VolumeControlIcon = ({
  width = '30px',
  height = '30px',
  viewBox = '0 0 30 30',
  onClick,
  className,
  fillColor,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid="unmuted"
    className={className}
    onClick={onClick}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.61977 10.4286H3V19.5714H7.61977V10.4286Z"
      stroke={fillColor || cssVariables['--secondary-color-5']}
      strokeWidth="1.5"
    />
    <path
      d="M14.5498 23H12.2399L7.62012 19.5714V10.4286L12.2399 7H14.5498V23Z"
      stroke={fillColor || cssVariables['--secondary-color-5']}
      strokeWidth="1.5"
    />
    <path
      d="M21.2256 18.1771L27.0003 12.4628"
      stroke={fillColor || cssVariables['--secondary-color-5']}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M21.2256 12.4628L27.0003 18.1771"
      stroke={fillColor || cssVariables['--secondary-color-5']}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

VolumeControlIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
  fillColor: PropTypes.string,
};

export default VolumeControlIcon;
