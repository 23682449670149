import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { seek } from 'src/common/actions/player';
import secondsToTime from 'src/common/utils/secondsToTime';
import useActions from '../../../../hooks/useActions';
import { selectIsMobile } from '../../../../selectors/app';
import { selectIsVideoAdDialogOpen } from '../../../../selectors/dialog';
import {
  selectCanScrub,
  selectPositionInfo,
  selectShowStartEnd,
} from '../../../../selectors/player';
import DefaultScrubber from './DefaultScrubber';
import LiveSeekScrubber from './LiveSeekScrubber';

const sliderMax = 100;

export default function Scrubber({ isInNowPlayingDialog, className }) {
  const canScrub = useSelector(selectCanScrub);
  const showStartEnd = useSelector(selectShowStartEnd);
  const isMobile = useSelector(selectIsMobile);
  const isVideoAdDialogOpen = useSelector(selectIsVideoAdDialogOpen);
  const actions = useActions({ seek });
  const {
    duration,
    elapsedPercent = 0,
    elapsedSeconds = 0,
    isLiveSeekStream,
  } = useSelector(selectPositionInfo);
  const elapsedTime = secondsToTime(elapsedSeconds);
  const durationTime = secondsToTime(duration);

  if (isLiveSeekStream && !isMobile) {
    return (
      <LiveSeekScrubber
        canScrub={!isVideoAdDialogOpen}
        sliderMax={sliderMax}
        elapsedTime={elapsedTime}
        durationTime={durationTime}
        elapsedPercent={elapsedPercent}
        elapsedSeconds={elapsedSeconds}
        onChangeCommitted={actions.seek}
      />
    );
  }

  return (
    <DefaultScrubber
      sliderMax={sliderMax}
      elapsedTime={elapsedTime}
      durationTime={durationTime}
      elapsedPercent={elapsedPercent}
      elapsedSeconds={elapsedSeconds}
      onChangeCommitted={actions.seek}
      isInNowPlayingDialog={isInNowPlayingDialog}
      canScrub={canScrub && !isVideoAdDialogOpen}
      showStartEnd={showStartEnd && !isVideoAdDialogOpen}
      className={className}
    />
  );
}

Scrubber.propTypes = {
  isInNowPlayingDialog: PropTypes.bool,
  className: PropTypes.string,
};
